<template>
    <div>
        <nav class="nav-bar" @mouseout="delayCloseActiveLink" :class="($route && $route.meta.navClass && !mobileMenuOpen) ? $route.meta.navClass : 'opaque'">
            <div class="logo">
                <router-link :to="{ name: 'HomePage-' + $route.meta.lang }"><img src="@/assets/img/logo-lelys.png" alt="Logo Groupe Lelys" title="Logo Groupe Lelys"></router-link>
            </div>
            <div class="links">
                <a href="#" class="link with-sublinks" :title="$t('Expertises')" @mouseover.stop="linkHoverActive('expertises')" @click.prevent>
                    <span>{{ $t('Expertises') }}</span>
                    <svg class="icon-down" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-6.10352e-05 1.37916L1.3791 0L4.99994 3.63158L8.62078 0L9.99994 1.37916L4.99994 6.37916L-6.10352e-05 1.37916Z" fill="white"/>
                    </svg>

                    <ul class="sublinks sublinks-expertises" :class="{ 'is-active': linkActive === 'expertises' }" v-if="globalsIsLoaded">
                        <svg height="14" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z" fill="#373A3F"/>
                        </svg>
                        <li v-for="item in globals.expertisesEntryShort.data" :key="item.id">
                            <router-link :to="item.jsonUrl" class="sublink" :title="item.title">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </a>
                <a href="#" class="link with-sublinks" :title="$t('Secteurs')" @mouseover.stop="linkHoverActive('secteurs')">
                    <span>{{ $t('Industries') }}</span>
                    <svg class="icon-down" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" @click.prevent>
                        <path d="M-6.10352e-05 1.37916L1.3791 0L4.99994 3.63158L8.62078 0L9.99994 1.37916L4.99994 6.37916L-6.10352e-05 1.37916Z" fill="white"/>
                    </svg>

                    <ul class="sublinks" :class="{ 'is-active': linkActive === 'secteurs' }" v-if="globalsIsLoaded">
                        <svg height="14" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z" fill="#373A3F"/>
                        </svg>
                        <li v-for="item in globals.secteursEntryShort.data" :key="item.id">
                            <router-link :to="item.jsonUrl" class="sublink" :title="item.title">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </a>
                <router-link :to="{ name: 'Realisations-' + $route.meta.lang }" class="link" :title="$t('Réalisations')">{{ $t('Réalisations') }}</router-link>
                <router-link :to="{ name: 'Contact-' + $route.meta.lang }" class="link" :title="$t('Contact')">{{ $t('Contact') }}</router-link>
            </div>
            <div class="right">
                <a v-if="contentIsLoaded && $route.meta.lang === 'fr'" :href="content.urls['en']" class="lang" title="EN">EN</a>
                <a v-if="contentIsLoaded && $route.meta.lang === 'en'" :href="content.urls['fr']" class="lang" title="FR">FR</a>
                <router-link :to="{ name: 'Soumission-' + $route.meta.lang }" class="site-btn" :title="$t('Demandez une soumission')">{{ $t('Demandez une soumission') }}</router-link>
                <div
                    id="hamburger"
                    @click.prevent.stop="
                        () => {
                            mobileMenuOpen = !mobileMenuOpen
                        }
                    "
                    :class="{ open: mobileMenuOpen }"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </nav>

        <!-- Mobile Menu -->
        <div class="mobile-menu-wrap" :class="{ open: mobileMenuOpen }" @click="closeAfterClick()" v-if="globalsIsLoaded">
            <nav>
                <a href="#" class="nav-link with-sublinks" :title="$t('Expertises')" @mouseover.stop="linkHoverActive('expertises')" @click.prevent>
                    <span>{{ $t('Expertises') }}</span>
                    <svg class="icon-down" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-6.10352e-05 1.37916L1.3791 0L4.99994 3.63158L8.62078 0L9.99994 1.37916L4.99994 6.37916L-6.10352e-05 1.37916Z" fill="white"/>
                    </svg>

                    <ul class="sublinks" :class="{ 'is-active': linkActive === 'expertises' }">
                        <svg height="14" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z" fill="#373A3F"/>
                        </svg>
                        <li v-for="item in globals.expertisesEntryShort.data" :key="item.id">
                            <router-link :to="item.jsonUrl" class="sublink" :title="item.title">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </a>
                <a href="#" class="nav-link with-sublinks" :title="$t('Secteurs')" @mouseover.stop="linkHoverActive('secteurs')" @click.prevent>
                    <span>{{ $t('Industries') }}</span>
                    <svg class="icon-down" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-6.10352e-05 1.37916L1.3791 0L4.99994 3.63158L8.62078 0L9.99994 1.37916L4.99994 6.37916L-6.10352e-05 1.37916Z" fill="white"/>
                    </svg>

                    <ul class="sublinks" :class="{ 'is-active': linkActive === 'secteurs' }">
                        <svg height="14" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5618 0.995983C11.761 -0.0211118 10.2197 -0.0211109 9.4189 0.995984L0.54857 12.2628C-0.484334 13.5748 0.450227 15.5 2.12 15.5L19.8607 15.5C21.5304 15.5 22.465 13.5748 21.4321 12.2628L12.5618 0.995983Z" fill="#373A3F"/>
                        </svg>
                        <li v-for="item in globals.secteursEntryShort.data" :key="item.id">
                            <router-link :to="item.jsonUrl" class="sublink" :title="item.title">{{ item.title }}</router-link>
                        </li>
                    </ul>
                </a>
                <router-link :to="{ name: 'Realisations-' + $route.meta.lang }" class="nav-link" :title="$t('Réalisations')">{{ $t('Réalisations') }}</router-link>
                <router-link :to="{ name: 'Contact-' + $route.meta.lang }" class="nav-link" :title="$t('Contact')">{{ $t('Contact') }}</router-link>
            </nav>
            <router-link
                :to="{ name: 'Soumission-' + $route.meta.lang }"
                class="site-btn alternate"
            >
                {{ $t('Demandez une soumission') }}
            </router-link>
            <div class="social">
                <a :href="globals.footer.reseaux.facebook" title="Facebook link" target="_blank" rel="noopener noreferrer">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM22.0846 20.8785V31.7596H17.5825V20.8789H15.3333V17.1293H17.5825V14.878C17.5825 11.819 18.8526 10 22.461 10H25.465V13.7501H23.5873C22.1826 13.7501 22.0897 14.2741 22.0897 15.2521L22.0846 17.1288H25.4863L25.0882 20.8785H22.0846Z"
                            fill="#F2F9F7"
                        />
                    </svg>
                </a>
                <a :href="globals.footer.reseaux.linkedin" title="LinkedIn link" target="_blank" rel="noopener noreferrer">
                    <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0ZM9.60137 16.5649H14.1338V30.183H9.60137V16.5649ZM14.4323 12.3522C14.4029 11.017 13.448 10 11.8975 10C10.347 10 9.3333 11.017 9.3333 12.3522C9.3333 13.6598 10.317 14.7061 11.8387 14.7061H11.8676C13.448 14.7061 14.4323 13.6598 14.4323 12.3522ZM25.2609 16.2451C28.2435 16.2451 30.4795 18.1919 30.4795 22.3748L30.4793 30.183H25.947V22.8973C25.947 21.0673 25.2912 19.8185 23.6504 19.8185C22.3982 19.8185 21.6524 20.6604 21.3248 21.4735C21.205 21.7649 21.1756 22.1709 21.1756 22.5779V30.1834H16.6426C16.6426 30.1834 16.7023 17.8431 16.6426 16.5653H21.1756V18.4941C21.7771 17.5669 22.8545 16.2451 25.2609 16.2451Z"
                            fill="#F2F9F7"
                        />
                    </svg>
                </a>
                <a :href="globals.footer.reseaux.youtube" title="Youtube link" target="_blank" rel="noopener noreferrer">
                    <svg width="40" height="40" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM21.251 9.84351C21.9394 10.0324 22.4816 10.5891 22.6656 11.2959C23 12.5771 23 15.2502 23 15.2502C23 15.2502 23 17.9232 22.6656 19.2044C22.4816 19.9113 21.9394 20.4679 21.251 20.6569C20.0033 21.0002 15 21.0002 15 21.0002C15 21.0002 9.99659 21.0002 8.74887 20.6569C8.06041 20.4679 7.51823 19.9113 7.33423 19.2044C6.99996 17.9232 6.99996 15.2502 6.99996 15.2502C6.99996 15.2502 6.99996 12.5771 7.33423 11.2959C7.51823 10.5891 8.06041 10.0324 8.74887 9.84351C9.99659 9.50018 15 9.50018 15 9.50018C15 9.50018 20.0033 9.50018 21.251 9.84351Z" fill="#59BBEB"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 18V13L17.5 15.5001L13.5 18Z" fill="#59BBEB"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'NavBar',

    data() {
        return {
            linkActive: '',
            delayer: null,
            mobileMenuOpen: false,
        }
    },

    computed: {
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['content', 'globals']),
    },

    methods: {
        closeAfterClick() {
            setTimeout(() => {
                this.mobileMenuOpen = false
            }, 55)
        },
        linkHoverActive(slug) {
            clearTimeout(this.delayer)
            this.delayer = null

            this.linkActive = slug
        },
        delayCloseActiveLink() {
            clearTimeout(this.delayer)
            this.delayer = null

            this.delayer = setTimeout(() => {
                if (document.querySelector('.sublinks.is-active')) {
                    document.querySelector('.sublinks.is-active').classList.remove('is-active')
                    this.linkActive = ''
                }
            }, 599)
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
