<template>
    <div id="app">
        <!-- NavBar -->
        <NavBar />

        <!-- Main page content -->
        <main>
            <div class="main-wrapper">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </div>
        </main>

        <!-- Footer -->
        <FooterBar />

        <PageLoader v-if="!contentIsLoaded" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import NavBar from '@/components/NavBar.vue'
import FooterBar from '@/components/FooterBar.vue'
import PageLoader from '@/components/PageLoader'

export default {
    components: {
        NavBar,
        FooterBar,
        PageLoader,
    },

    watch: {
        $route: {
            handler() {
                if (window.location.href.includes('en.lelys')) {
                    // window.location.href = window.location.href.replace('en.lelys.com/', 'lelys.com/en/')
                    window.location.href = window.location.href.replace('en.lelys.com', 'lelys.com')
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },

    mounted () {
        window.addEventListener(
            'scroll',
            () => {
                const top = window.scrollY

                if (top >= 1) {
                    document.querySelector('body').classList.add('nav-is-fixed')
                    if (document.querySelector('.nav-bar.opaque')) {
                        document.querySelector('.page-wrapper').style.paddingTop = `${ document.querySelector('.nav-bar.opaque').offsetHeight }px`
                    }
                    return
                }

                document.querySelector('body').classList.remove('nav-is-fixed')
                document.querySelector('.page-wrapper').style.paddingTop = '0px'
            },
            false
        )
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: translate(0, 10vw) scale(1.1225) rotate(0);
}
</style>
