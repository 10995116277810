import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/i18n'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'HomePage-fr',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'fr', json: 'home', 'navClass': 'light' },
    },
    {
        path: '/en',
        name: 'HomePage-en',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        meta: { lang: 'en', json: 'home', 'navClass': 'light' },
    },
    {
        path: '/expertise/:slug',
        name: 'Expertises-fr',
        component: () => import(/* webpackChunkName: "expertises" */ '../views/expertises/Entry.vue'),
        meta: { lang: 'fr', json: 'expertisesEntry', slug: true },
    },
    {
        path: '/en/expertise/:slug',
        name: 'Expertises-en',
        component: () => import(/* webpackChunkName: "expertises" */ '../views/expertises/Entry.vue'),
        meta: { lang: 'en', json: 'expertisesEntry', slug: true },
    },
    {
        path: '/industries/:slug',
        name: 'Secteurs-fr',
        component: () => import(/* webpackChunkName: "secteurs" */ '../views/secteurs/Entry.vue'),
        meta: { lang: 'fr', json: 'secteursEntry', slug: true },
    },
    {
        path: '/en/industries/:slug',
        name: 'Secteurs-en',
        component: () => import(/* webpackChunkName: "secteurs" */ '../views/secteurs/Entry.vue'),
        meta: { lang: 'en', json: 'secteursEntry', slug: true },
    },
    {
        path: '/realisations',
        name: 'Realisations-fr',
        component: () => import(/* webpackChunkName: "realisations" */ '../views/realisations/Index.vue'),
        meta: { lang: 'fr', json: 'realisations' },
    },
    {
        path: '/en/our-works',
        name: 'Realisations-en',
        component: () => import(/* webpackChunkName: "realisations" */ '../views/realisations/Index.vue'),
        meta: { lang: 'en', json: 'realisations' },
    },
    {
        path: '/a-propos',
        name: 'About-fr',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { lang: 'fr', json: 'aboutUs' },
    },
    {
        path: '/en/about-us',
        name: 'About-en',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { lang: 'en', json: 'aboutUs' },
    },
    {
        path: '/carriere',
        name: 'Carriere-fr',
        component: () => import(/* webpackChunkName: "career" */ '../views/Carriere.vue'),
        meta: { lang: 'fr', json: 'carriere' },
    },
    {
        path: '/en/career',
        name: 'Carriere-en',
        component: () => import(/* webpackChunkName: "career" */ '../views/Carriere.vue'),
        meta: { lang: 'en', json: 'carriere' },
    },
    {
        path: '/faq',
        name: 'Faq-fr',
        component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
        meta: { lang: 'fr', json: 'faq' },
    },
    {
        path: '/en/faq',
        name: 'Faq-en',
        component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue'),
        meta: { lang: 'en', json: 'faq' },
    },
    {
        path: '/contact',
        name: 'Contact-fr',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'fr', json: 'contact' },
    },
    {
        path: '/en/contact',
        name: 'Contact-en',
        component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
        meta: { lang: 'en', json: 'contact' },
    },
    {
        path: '/soumission',
        name: 'Soumission-fr',
        component: () => import(/* webpackChunkName: "soumission" */ '../views/Soumission.vue'),
        meta: { lang: 'fr', json: 'soumission' },
    },
    {
        path: '/en/soumission',
        name: 'Soumission-en',
        component: () => import(/* webpackChunkName: "soumission" */ '../views/Soumission.vue'),
        meta: { lang: 'en', json: 'soumission' },
    },
    {
        path: '/tendances',
        name: 'TendancesIndex-fr',
        component: () => import(/* webpackChunkName: "tendances-index" */ '@/views/tendances/Index.vue'),
        meta: { lang: 'fr', json: 'tendances' },
    },
    {
        path: '/en/trends',
        name: 'TendancesIndex-en',
        component: () => import(/* webpackChunkName: "tendances-index" */ '@/views/tendances/Index.vue'),
        meta: { lang: 'en', json: 'tendances' },
    },
    {
        path: '/tendance/:slug',
        name: 'TendancesEntry-fr',
        component: () => import(/* webpackChunkName: "tendances-entry" */ '../views/tendances/Entry.vue'),
        meta: { lang: 'fr', json: 'articlesEntry', slug: true },
    },
    {
        path: '/en/trend/:slug',
        name: 'TendancesEntry-en',
        component: () => import(/* webpackChunkName: "tendances-entry" */ '../views/tendances/Entry.vue'),
        meta: { lang: 'en', json: 'articlesEntry', slug: true },
    },
    {
        path: '/plan-du-site',
        name: 'Plan-fr',
        component: () => import(/* webpackChunkName: "plan" */ '../views/Terms.vue'),
        meta: { lang: 'fr', json: 'planDuSite' },
    },
    {
        path: '/en/site-map',
        name: 'Plan-en',
        component: () => import(/* webpackChunkName: "plan" */ '../views/Terms.vue'),
        meta: { lang: 'en', json: 'planDuSite' },
    },
    {
        path: '/politique-de-confidentialite',
        name: 'Politique-fr',
        component: () => import(/* webpackChunkName: "politique" */ '../views/Terms.vue'),
        meta: { lang: 'fr', json: 'politiqueDeConfidentialite' },
    },
    {
        path: '/en/privacy-policy',
        name: 'Politique-en',
        component: () => import(/* webpackChunkName: "politique" */ '../views/Terms.vue'),
        meta: { lang: 'en', json: 'politiqueDeConfidentialite' },
    },
    {
        path: '/conditions-utilisations',
        name: 'Terms-fr',
        component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
        meta: { lang: 'fr', json: 'conditionsDutilisation' },
    },
    {
        path: '/en/terms-of-use',
        name: 'Terms-en',
        component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
        meta: { lang: 'en', json: 'conditionsDutilisation' },
    },
    {
        path: '/*',
        name: 'p404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
        meta: { lang: 'fr', json: 'home' },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
})

router.beforeEach((to, from, next) => {
    const { lang } = to.meta
    document.documentElement.lang = lang
    i18n.locale = lang

    if (Store.getters['locale'] !== lang) {
        Store.dispatch('getGlobals')
        Store.dispatch('setLocale', lang)
    }

    setTimeout(() => {
        Store.dispatch('resetContent')

        let type = 'pages'
        const { meta, params } = to
        let slug = meta.json
        let item = ''

        if (meta.slug) {
            item = params.slug
            type = '' // Use slug and ignore type
        }

        Store.dispatch('getContent', { lang: meta.lang, slug, type, item })
    }, 1)

    next()
})

export default router
