<template>
    <footer class="footer" v-if="globalsIsLoaded">
        <section class="infolettre">
            <!-- <div class="left">
                <h3 class="title">{{ globals.footer.infolettre.surtitre }}</h3>
                <h4 class="subtitle medium-title"><span v-html="globals.footer.infolettre.titre"></span></h4>
                <form class="inline-form" action="">
                    <input class="inline-input" type="email" :placeholder="globals.footer.infolettre.champ">
                    <button type="submit">
                        <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.9693 1.56786L15.7828 6.38035L-3.76777e-07 6.38035L-2.7889e-07 8.61973L15.7828 8.61973L10.9693 13.4322L12.537 15L20.036 7.5L12.537 -5.48012e-07L10.9693 1.56786Z" fill="white"/>
                        </svg>
                    </button>
                </form>
            </div> -->
            <div class="right">
                <h3 class="title">{{ $t('Certifications') }}</h3>
                <div class="logos">
                    <img v-for="item in globals.footer.certifications" :key="item.titre" :src="item.url" :alt="item.titre" :title="item.titre">
                </div>
            </div>
        </section>

        <section class="links">
            <div class="col">
                <h2 class="title">{{ $t('Expertises') }}</h2>
                <ul>
                    <li v-for="(item, i) in globals.expertisesEntryShort.data" :key="'e-' + i">
                        <router-link :to="item.jsonUrl" :title="item.title">{{ item.title }}</router-link>
                    </li>
                </ul>
            </div>
            <div class="col">
                <h2 class="title">{{ $t('Industries') }}</h2>
                <ul>
                    <li v-for="(item, i) in globals.secteursEntryShort.data" :key="'s-' + i">
                        <router-link :to="item.jsonUrl" :title="item.title">{{ item.title }}</router-link>
                    </li>
                </ul>
            </div>
            <div class="col">
                <h2 class="title">Groupe Lelys</h2>
                <ul>
                    <li><router-link :to="{ name: 'About-'  + $route.meta.lang}" :title="$t('À propos')">{{ $t('À propos') }}</router-link></li>
                    <!-- <li><router-link :to="{ name: 'Carriere-'  + $route.meta.lang}" :title="$t('Carrière')">{{ $t('Carrière') }}</router-link></li> -->
                    <!-- <li><router-link :to="{ name: 'TendancesIndex-'  + $route.meta.lang}" :title="$t('Tendances')">{{ $t('Tendances') }}</router-link></li> -->
                    <li><router-link :to="{ name: 'Contact-'  + $route.meta.lang}" title="Contact">{{ $t('Contact') }}</router-link></li>
                    <!-- <li><router-link :to="{ name: 'Faq-'  + $route.meta.lang}" title="FAQ">{{ $t('FAQ') }}</router-link></li> -->
                </ul>
                <p class="reseau-title">{{ $t('Suivez-nous sur') }}</p>
                <div class="reseaux">
                    <a :href="globals.footer.reseaux.facebook" class="" title="Facebook">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM16.5635 15.6589V23.8197H13.1869V15.6592H11.5V12.8469H13.1869V11.1585C13.1869 8.86425 14.1395 7.5 16.8458 7.5H19.0988V10.3126H17.6905C16.637 10.3126 16.5673 10.7056 16.5673 11.4391L16.5635 12.8466H19.1148L18.8162 15.6589H16.5635Z" fill="#59BBEB"/>
                        </svg>
                    </a>
                    <a :href="globals.footer.reseaux.linkedin" class="" title="LinkedIn">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM7.20098 12.4237H10.6003V22.6372H7.20098V12.4237ZM10.8242 9.26419C10.8022 8.26275 10.086 7.5 8.92316 7.5C7.76027 7.5 7.00001 8.26275 7.00001 9.26419C7.00001 10.2449 7.7378 11.0296 8.87903 11.0296H8.90075C10.086 11.0296 10.8242 10.2449 10.8242 9.26419ZM18.9457 12.1839C21.1826 12.1839 22.8596 13.644 22.8596 16.7812L22.8595 22.6374H19.4603V17.1731C19.4603 15.8006 18.9684 14.864 17.7378 14.864C16.7987 14.864 16.2393 15.4954 15.9936 16.1052C15.9037 16.3238 15.8816 16.6283 15.8816 16.9335V22.6376H12.4819C12.4819 22.6376 12.5267 13.3824 12.4819 12.4241H15.8816V13.8707C16.3328 13.1753 17.1409 12.1839 18.9457 12.1839Z" fill="#59BBEB"/>
                        </svg>
                    </a>
                    <a :href="globals.footer.reseaux.youtube" class="" title="Youtube">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM21.251 9.84351C21.9394 10.0324 22.4816 10.5891 22.6656 11.2959C23 12.5771 23 15.2502 23 15.2502C23 15.2502 23 17.9232 22.6656 19.2044C22.4816 19.9113 21.9394 20.4679 21.251 20.6569C20.0033 21.0002 15 21.0002 15 21.0002C15 21.0002 9.99659 21.0002 8.74887 20.6569C8.06041 20.4679 7.51823 19.9113 7.33423 19.2044C6.99996 17.9232 6.99996 15.2502 6.99996 15.2502C6.99996 15.2502 6.99996 12.5771 7.33423 11.2959C7.51823 10.5891 8.06041 10.0324 8.74887 9.84351C9.99659 9.50018 15 9.50018 15 9.50018C15 9.50018 20.0033 9.50018 21.251 9.84351Z" fill="#59BBEB"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 18V13L17.5 15.5001L13.5 18Z" fill="#59BBEB"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="col infos">
                <h2 class="title">{{ $t('Soumission') }}</h2>
                <p><span v-html="globals.footer.soumission"></span></p>
                <a :href="'tel:' + globals.contact.contactInfos.telephone" class="tel">{{ globals.contact.contactInfos.telephone }}</a>
                <router-link :to="{ name: 'Soumission-' + $route.meta.lang }" class="site-btn hover-white" title="cta">{{ $t('Demandez une soumission') }}</router-link>
            </div>
        </section>

        <section class="legals">
            <div class="left">
                <router-link :to="{ name: 'Plan-' + $route.meta.lang }" title="Plan">{{ $t('Plan du site') }}</router-link>
                <router-link :to="{ name: 'Politique-' + $route.meta.lang }" title="">{{ $t('Politique de confidentialité') }}</router-link>
                <router-link :to="{ name: 'Terms-' + $route.meta.lang }" title="Conditions">{{ $t("Conditions d'utilisation") }}</router-link>
            </div>
            <a class="right" href="https://elmire.ca/" target="_blank" rel="noopener noreferrer">
                <p>{{ $t('Conception Elmire') }}</p>
                <img src="@/assets/img/logo-elmire.png" alt="Logo Elmire" title="Logo Elmire">
            </a>
        </section>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'FooterBar',

    computed: {
        ...mapState(['globalsIsLoaded']),
        ...mapGetters(['globals']),
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
